import { ConfigureOptions, Preferences } from '@capacitor/preferences'

interface ICapacitorPreferences {
  get: (key: string) => Promise<string | null>
  set: (key: string, value: string) => Promise<void>
  remove: (key: string) => Promise<void>
  clear: () => Promise<void>
  removeOld: () => Promise<void>
  migrate: () => Promise<void>
  keys: () => Promise<string[]>
  configure: (options: ConfigureOptions) => Promise<void>
}

export default class CapacitorPreferences implements ICapacitorPreferences {
  async get (key: string) {
    const result = await Preferences.get({ key })
    return result.value
  }

  async set (key: string, value: string) {
    await Preferences.set({ key, value })
  }

  async remove (key: string) {
    await Preferences.remove({ key })
  }

  async clear () {
    await Preferences.clear()
  }

  async removeOld () {
    await Preferences.removeOld()
  }

  async migrate () {
    await Preferences.migrate()
  }

  async keys () {
    const result = await Preferences.keys()
    return result.keys
  }

  async configure (options: ConfigureOptions) {
    return await Preferences.configure(options)
  }
}
